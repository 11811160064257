<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <!--        <FormItem>-->
        <!--          <span>订单号：</span>-->
        <!--          <i-input placeholder="请输入" class="iviewIptWidth250 marginRight60"></i-input>-->
        <!--        </FormItem>-->
        <FormItem>
          <span>供应商名称：</span>
          <Select class="iviewIptWidth250 marginRight60" @on-select="supplierSelect" @on-change="supplier" clearable filterable v-model="supplierId">
            <Option v-for="(item, index) in supplierList" :value="item.supplier_id" :key="item.supplier_id + index">{{ item.supplier_name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>产品名称：</span>
          <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" :disabled="!queryFrom.supplier_id" v-model="queryFrom.product_id" clearable filterable>
            <Option v-for="item in productList" :value="item.product_id" :key="item.supplier_product_id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>规格型号/SKU：</span>
          <Select class="iviewIptWidth250 marginRight60" :disabled="!queryFrom.supplier_id" multiple filterable clearable v-model="queryFrom.code_id" :max-tag-count="queryFrom.code_id.length == 1 ? 1 : 0">
            <Option v-for="(item, index) in modelList" :value="item.code_id" :key="index" :label="item.model_name + '-' + item.item_number">
              <span>{{ item.model_name }}</span>
              <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
            </Option>
          </Select>
        </FormItem>
        <FormItem>
          <span>收货仓库：</span>
          <Select class="iviewIptWidth250" v-model="postFrom.warehouse_id" filterable clearable @on-change="warehouseIdChange">
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="queryProductLookup">查询</span>
          <span class="pageBtn finger btnSure marginLeft15" @click="tempDownLoad">模板下载</span>
          <span class="pageBtn finger btnSure marginLeft15">
            <FormUploadFile label="导入" name="xlsx_file" :multiple="false" :apiSrc="apiSrc" @get-data="importTemplate"></FormUploadFile>
          </span>
        </FormItem>
      </Form>
      <Table :columns="listColumns" :data="meterList" border no-data-text="暂无产品">
        <template slot-scope="{ row, index }" slot="unit_price">
          <div class="danjia">
            <span class="fl">¥ </span>
            <!--            <i-input class="fl" :value="row.unit_price" maxlength="8" placeholder="请输入" style="width: 100px;" @on-change="changeIpt($event, 'unit_price', index)"></i-input>-->
            <InputNumber :max="999999999.99" :min="0" :precision="2" v-model="row.unit_price" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'unit_price', index)"></InputNumber>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="num">
          <!--          <i-input placeholder="请输入" type="number" :value="row.number" @on-change="changeIpt($event, 'number', index)"></i-input>-->
          <InputNumber :max="999999" :min="0" :precision="0" v-model="row.number" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'number', index)"></InputNumber>
        </template>
      </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0">
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div>
      <div class="summary" v-if="totleMoney > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ allNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totleMoney }}</span>
        </div>
      </div>
      <ul class="markUl">
        <li v-for="(item, index) in markList" :key="index" :class="{ iptDiv: true, inpiptDiv: index != markList.length - 1 }">
          <div class="tipsFl">{{ item.label }}</div>
          <div class="tipsFr">
            <i-input maxlength="100" :type="index == 2 ? 'number' : index == 3 ? 'textarea' : 'text'" show-word-limit placeholder="请输入" v-model="postFrom[item.key]"></i-input>
          </div>
        </li>
      </ul>
      <!--      <div class="iptDiv inpiptDiv">-->
      <!--        <div class="tipsFl">收货地址</div>-->
      <!--        <div class="tipsFr">-->
      <!--          <i-input maxlength="100" show-word-limit placeholder="请输入" v-model="postFrom.address"></i-input>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="iptDiv">-->
      <!--        <div class="tipsFl">备注</div>-->
      <!--        <div class="tipsFr">-->
      <!--          <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" v-model="postFrom.note"></i-input>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="pageBtm clearfix">
      <span class="pageBtn finger btnSure marginLeft20 fr" @click="save(1)">提交</span>
      <span class="pageBtn finger btnSure fr marginLeft20" @click="save(0)">保存</span>
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!--    弹窗选择数据-->
    <Modal v-model="chooseStatus" label-position="left" width="1200" :footer-hide="true" class-name="vertical-center-modal" title="产品列表">
      <div v-if="chooseStatus">
        <Table :columns="chooseColumns" :data="chooseList" border @on-selection-change="selectChange" height="500"></Table>
        <div class="clearfix" style="margin-top: 20px;">
          <span class="pageBtn finger btnSure fr" @click="sureChoose">确定</span>
        </div>
      </div>
    </Modal>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="changeSupplierStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否更换供应商名称？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="sureChangeSupplier">确定</span>
          <span class="pageBtn finger btnCancle" @click="cancleChangeSupplier">取消</span>
        </div>
      </div>
    </Modal>
    <!--    删除产品-->
    <tips-component showModal="delete" v-if="deleteStatus" @cancleBtn="deleteStatus = false" @sureBrn="sureDelete"></tips-component>
    <!--    提交-->
    <tips-component showModal="post" v-if="postStatus" @cancleBtn="postStatus = false" @sureBrn="surePost"></tips-component>
  </div>
</template>

<script>
import TipsComponent from '@/components/tipsComponent'
import FormUploadFile from '@/components/formUploadFile.vue'
import http from '@/service/baseUrl'
export default {
  name: 'addPurchaseOrderConsign',
  components: {
    TipsComponent,
    FormUploadFile,
  },
  computed: {
    allNum() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.number * 1
      })
      return totle
    },
    totleMoney() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.unit_price * 1 * (e.number ? e.number : 1)
      })
      return totle.toFixed(2)
    },
  },
  data() {
    return {
      markList: [
        {
          label: '收货地址',
          key: 'address',
        },
        {
          label: '联系人',
          key: 'contact_name',
        },
        {
          label: '联系电话',
          key: 'phone',
        },
        {
          label: '备注',
          key: 'note',
        },
      ],
      apiSrc: '',
      supplierId: '',
      postStatus: false,
      deleteStatus: false,
      changeSupplierStatus: false,
      queryFrom: {
        supplier_id: '',
        product_id: '',
        code_id: '',
      },
      postFrom: {
        supplier_id: '',
        line_info: [],
        warehouse_id: '',
        note: '',
        address: '',
        contact_name: '',
        phone: '',
      },
      supplierList: [],
      productList: [],
      modelList: [],
      storeList: [],
      meterList: [], // 已选产品列表
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '物料号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 130,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'licence_code',
          align: 'center',
          minWidth: 180,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 115,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 115,
        },
        {
          title: '单价',
          slot: 'unit_price',
          align: 'center',
          width: 140,
        },
        {
          title: '税率',
          key: 'tax_rate',
          align: 'center',
          width: 140,
          render: (h, param) => {
            return h('div', [h('span', param.row.tax_rate + '%')])
          },
        },
        {
          title: '不含税价',
          key: 'tax_rate_price',
          align: 'center',
          width: 140,
          render: (h, param) => {
            return h('div', [h('span', (param.row.unit_price / (1 + (param.row.tax_rate ? param.row.tax_rate : 0) / 100)).toFixed(4))])
          },
        },
        {
          title: '数量',
          slot: 'num',
          align: 'center',
          width: 140,
        },
        {
          title: '库存余量',
          key: 'inventory_quantity',
          align: 'center',
          width: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 85,
        },
        {
          title: '包装规格',
          key: 'packing_size',
          align: 'center',
          width: 114,
        },
        {
          title: '有效天数',
          key: 'valid_days',
          align: 'center',
          width: 140,
        },
        {
          title: '厂家',
          key: 'factory_name',
          align: 'center',
          width: 85,
        },
        {
          title: '小计',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.total ? '¥' + param.row.total : '0')])
          },
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 100,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.deleteRow(param.index)
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      chooseStatus: false,
      chooseList: [],
      chooseColumns: [
        {
          type: 'selection',
          align: 'center',
          width: 55,
        },
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          width: 150,
        },
        {
          title: '产品名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          width: 150,
        },
        {
          title: '货号/SKU',
          key: 'item_number',
          align: 'center',
          width: 120,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          width: 70,
        },
        {
          title: '单价',
          align: 'center',
          width: 90,
          render: (h, param) => {
            return h('div', [h('span', param.row.unit_price ? '¥' + param.row.unit_price : 0)])
          },
        },
        {
          title: '库存余量',
          key: 'inventory_quantity',
          align: 'center',
          width: 90,
        },
        {
          title: '厂家名称',
          key: 'factory_name',
          align: 'center',
          filters: this.factoryNameList,
          filterMethod(value, row) {
            return row.factory_name.indexOf(value) > -1
          },
        },
      ],
      hadSelectList: [], // 弹窗已选数据
      clickIndex: Number, // 暂存点击删除数据下标
      flag: true,
      factoryNameList: [],
    }
  },
  created() {
    this.apiSrc = http.http + this.$apiConsign.getPurchaseOrderImport.url
  },
  mounted() {
    this.querySupplierSelect()
    this.queryList()
  },
  methods: {
    // 仓库更改 带出基本信息
    warehouseIdChange(e) {
      this.storeList.forEach(item => {
        if (item.warehouse_id == e) {
          this.postFrom.address = item.address
          this.postFrom.contact_name = item.contact_name
          this.postFrom.phone = item.phone
        }
      })

      if (this.meterList.length == 0) return

      let arr = []
      this.meterList.forEach(item => {
        let obj = {
          product_model_code: item.product_model_code,
          inventory_quantity: item.inventory_quantity,
        }
        arr.push(obj)
      })

      // 仓库更改  查询库存信息
      this.$http.put(this.$apiConsign.productInventoryQuantity, { warehouse_id: e, product_info: arr }).then(res => {
        if (res.status) {
          this.meterList.forEach(item => {
            res.data.forEach(item2 => {
              if (item.product_model_code === item2.product_model_code) {
                item.inventory_quantity = item2.inventory_quantity
              }
            })
          })
        }
      })
    },
    // 筛选厂家名称
    srceenFactoryName() {
      this.factoryNameList = []
      this.chooseList.forEach(item => {
        this.factoryNameList.push({
          label: item.factory_name,
          value: item.factory_name,
        })
      })
      // 去重
      let obj = {}
      this.factoryNameList = this.factoryNameList.reduce((cur, next) => {
        if (!obj[next.label]) {
          cur.push({
            label: next.label,
            value: next.label,
          })
          obj[next.label] = next.label
        }
        return cur
      }, [])
      this.chooseColumns.forEach(item => {
        if (item.key == 'factory_name') {
          item.filters = this.factoryNameList
        }
      })
    },
    // 模板下载
    tempDownLoad() {
      this.$http.downFile(this.$apiConsign.templateDownloadCgs, {}, false).then(res => {
        let blob = new Blob([res.data], { type: 'application/zip' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '采购订单模板.xlsx'
        aLink.click()
      })
    },
    // 采购单导入
    importTemplate(res) {
      this.chooseStatus = true
      if (res.line_info.length > 0) {
        res.line_info.forEach(item => {
          item.name = item.product_name ? item.product_name : ''
          ;(item.unit = item.packing_size ? item.packing_size : ''), (item.inventory_quantity = item.inventory_quantity ? item.inventory_quantity : 0)
        })
        this.chooseList = res.line_info
        //  获取厂家名称分类
        this.srceenFactoryName()
        this.supplierId = res.supplier_id
        this.queryFrom.supplier_id = res.supplier_id
      }
    },
    back() {
      this.$router.go(-1)
    },
    // 查询仓库列表
    queryList() {
      this.isLoad = true
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    // 确认删除
    sureDelete() {
      this.meterList.splice(this.clickIndex, 1)
      this.deleteStatus = false
      this.$Message.success('删除成功')
    },
    // 点击删除按钮
    deleteRow(index) {
      this.clickIndex = index
      this.deleteStatus = true
    },
    // 确认选择 关闭弹窗
    sureChoose() {
      if (this.hadSelectList.length == 0) {
        this.chooseStatus = false
        return
      }
      this.chooseStatus = false
      if (this.meterList.length > 0) {
        // let select = this.meterList.map(item => item.product_id + item.code_id)
        // this.hadSelectList = this.hadSelectList.filter(i => {
        //   return !select.includes(i.product_id + i.code_id)
        // })
        let arr = [...this.meterList, ...this.hadSelectList]
        this.meterList = JSON.parse(JSON.stringify(arr))
      } else {
        this.meterList = JSON.parse(JSON.stringify(this.hadSelectList))
      }
      this.meterList.forEach((e, i) => {
        let num = this.meterList[i].number ? this.meterList[i].number : 0
        this.$set(this.meterList[i], 'number', num)
        this.$set(this.meterList[i], 'total', this.meterList[i].number * this.meterList[i].unit_price)
      })
    },
    // 选择数据发生改变时
    selectChange(list) {
      this.hadSelectList = list
    },
    // 点击查询按钮 查询弹窗
    queryProductLookup() {
      if (!this.queryFrom.supplier_id) {
        this.$Message.warning('请先选择供应商名称再查询')
        return
      }
      let query = {
        supplier_id: this.queryFrom.supplier_id,
        product_id: this.queryFrom.product_id,
        code_id_str: this.queryFrom.code_id,
        warehouse_id: this.postFrom.warehouse_id,
      }

      this.$http.get(this.$apiConsign.productLookup, query, true).then(res => {
        if (res.data.length > 0) {
          this.factoryNameList = []
          this.hadSelectList = []
          this.chooseList = res.data
          //  获取厂家名称分类
          this.srceenFactoryName()
          this.chooseStatus = true
          console.log(this.chooseList, '看数据')
        } else {
          this.$Message.warning('查询暂无数据')
        }
      })
    },
    // 在已有产品的情况下 确认更换供应商
    sureChangeSupplier() {
      this.changeSupplierStatus = false
      this.queryFrom.product_id = ''
      this.queryFrom.supplier_id = this.supplierId
      this.productList = []
      this.meterList = []
      this.queryProductSelect(this.supplierId)
    },
    // 在已有产品的情况下 取消更改供应商
    cancleChangeSupplier() {
      this.supplierId = this.queryFrom.supplier_id
      this.queryFrom.code_id = []
      this.changeSupplierStatus = false
    },
    supplier(e) {
      if (this.meterList.length > 0) {
        this.changeSupplierStatus = true
        return null
      } else {
        if (!e) {
          this.supplierId = null
          this.queryFrom.supplier_id = null
        }
      }
    },
    // 供应商名称修改 -- 考虑到有产品等其他情况下
    supplierSelect(e) {
      this.supplierId = e.value
      if (this.queryFrom.supplier_id && this.meterList.length > 0) {
        this.changeSupplierStatus = true
        return null
      } else {
        this.queryFrom.code_id = []
        this.queryFrom.product_id = ''
        if (!e.value) return
        this.queryFrom.supplier_id = e.value
        this.productList = []
        this.queryProductSelect(e.value)
      }
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.code_id = []
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下的产品下拉选择
    queryProductSelect(id) {
      this.$http.get(this.$apiConsign.productSelect, { supplier_id: id }, false).then(res => {
        this.productList = res.data
      })
      // 同时获取此供应商下的所有规格型号产品
      this.queryProductCodeSelect()
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$apiConsign.productCodeSelect, { product_id: id, supplier_id: this.queryFrom.supplier_id }, true).then(res => {
        this.modelList = res.data
      })
    },
    // 供应商名称
    querySupplierSelect() {
      this.$http.fetch(this.$apiConsign.supplierSelect, true).then(res => {
        this.supplierList = res.data
      })
    },
    changeTotal(e, name, index) {
      this.$set(this.meterList[index], name, e * 1)
      let total = this.meterList[index].number * this.meterList[index].unit_price
      this.$set(this.meterList[index], 'total', total > 0 && total ? total.toFixed(2) : 0)
    },
    // 输入框改变
    changeIpt(e, name, index) {
      if (e.target.value * 1 < 0) {
        this.$set(this.meterList[index], name, 1)
      } else {
        this.$set(this.meterList[index], name, e.target.value.trim() * 1)
      }
      let total = this.meterList[index].number * this.meterList[index].unit_price
      this.$set(this.meterList[index], 'total', total > 0 ? total.toFixed(2) : 0)
    },
    // 保存
    save(num) {
      if (!this.queryFrom.supplier_id) {
        this.$Message.warning('请选择供应商名称')
        return
      }
      if (!this.postFrom.warehouse_id) {
        this.$Message.warning('请选择收货仓库')
        return
      }
      if (this.meterList.length == 0) {
        this.$Message.warning('请至少添加一个产品')
        return
      }
      for (let i = 0; i < this.meterList.length; i++) {
        // 不含税单价
        if (this.meterList[i].unit_price == 0) {
          this.meterList[i].total = 0
          this.meterList[i].tax_rate_price = 0
        } else {
          this.meterList[i].tax_rate_price = this.meterList[i].unit_price / (1 + (this.meterList[i].tax_rate ? this.meterList[i].tax_rate : 0) / 100)
        }
        // this.meterList[i].tax_rate_price = this.meterList[i].unit_price/(1+(this.meterList[i].tax_rate?this.meterList[i].tax_rate:0)/100)
        if (!this.meterList[i].number) {
          this.$Message.warning(`请完善第${i + 1}组产品的数量`)
          return
        }
        if (!this.meterList[i].unit_price) {
          this.$Message.warning(`单价不能填写为0，请修改为0.01`)
          return
        }
      }
      if (!this.flag) return
      this.postFrom.supplier_id = this.queryFrom.supplier_id
      this.postFrom.line_info = JSON.parse(JSON.stringify(this.meterList))
      const regExp = /^1[345789]\d{9}$/
      if (!regExp.test(this.postFrom.phone)) {
        this.$Message.warning('请输入正确的联系电话(手机号)！')
        return
      }
      if (num) {
        this.postFrom.is_submit_order = '1'
        sessionStorage.setItem('updataCache', '0')
        this.postStatus = true
        return
      } else {
        this.postFrom.is_submit_order = '0'
      }
      this.flag = false
      this.$http
        .post(this.$apiConsign.pruchaseOrderManage, this.postFrom, true)
        .then(res => {
          this.flag = true
          this.$Message.success('保存成功')
          this.$router.go(-1)
        })
        .catch(err => {
          this.flag = true
        })
      sessionStorage.setItem('updataCache', '0')
    },
    // 最终确认提交
    surePost() {
      this.postStatus = false
      this.flag = false
      this.$http
        .post(this.$apiConsign.pruchaseOrderManage, this.postFrom, true)
        .then(res => {
          this.flag = true
          this.$Message.success('提交成功')
          this.$router.go(-1)
        })
        .catch(err => {
          this.flag = true
        })
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
    .markUl {
      margin-top: 20px !important;
    }
    .inpiptDiv {
      height: 50px !important;
      .tipsFl {
        line-height: 50px !important;
      }
    }
    .iptDiv {
      height: 150px;
      display: flex;
      border-top: 1px solid #e8eaec;
      border-left: 1px solid #e8eaec;
      border-right: 1px solid #e8eaec;
      .tipsFl {
        width: 210px;
        line-height: 150px;
        padding-left: 33px;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }
      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;
        .ivu-input-wrapper {
          height: 100% !important;
        }
        .ivu-input {
          height: 100% !important;
        }
      }
    }
    .iptDiv:last-child {
      border: 1px solid #e8eaec;
    }
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 30px;
}
.marginLeft15 {
  margin-left: 15px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
